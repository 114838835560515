<template>
  <div class="videolist">
    <van-nav-bar left-arrow :border="false" fixed @click-left="LeftClick" @click-right="RightClick">
      <template #title>
        <van-search
          v-model="keywords"
          ref="keywords"
          clearable
          placeholder="请输入搜索关键词"
          @search="onSearch"
          @cancel="onCancel"
          @focus="inputFocus"
          @blur="inputBlur"
          shape="round"
        />
      </template>
      <template #right>
        <!-- <van-icon name="search" size="18" /> -->
        <div>{{rightText}}</div>
      </template>
    </van-nav-bar>
    <div class="main">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <div v-if="!nodata">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            :immediate-check="false"
          >
            <div class="main_list" v-if="list && list.length > 0">
              <van-grid :column-num="2" :border="false">
                <van-grid-item v-for="(item, index) in list" :key="index" @click="detail(index)">
                  <div class="list_miaoshu" :style="'width: '+((screenWidth-32)/2)+'px;height: '+((screenWidth-32)/2/315*230)+'px;'">
                    <van-image
                      v-if="item.thumb && item.thumb.length > 10"
                      :src="item.thumb"
                      height="100%"
                      width="100%"
                      fit="contain"
                    >
                      <template v-slot:loading>
                        <van-loading type="spinner" size="20" />
                      </template>
                    </van-image>
                    <div class="list_text">
                      <div class="list_item_icon" :style="'height: 30px;width:30px;top: '+(((screenWidth-30)/2/315*230-50-30)/2)+'px;'">
                        <van-icon :name="item.isSalse == 1 ? 'lock' : 'play'" size="30" color="#fff" />
                      </div>
                      <div class="list_text_item1">精确引流绝技</div>
                      <div class="list_text_item2">云店商学院 · {{item.views}}人已学习</div>
                    </div>
                  </div>
                </van-grid-item>
              </van-grid>
            </div>
          </van-list>
        </div>
        <div v-if="nodata">
          <van-empty description="暂无教程" />
        </div>
      </van-pull-refresh>
    </div>
    <van-tabbar v-model="active">
      <van-tabbar-item @click="goHome">首页</van-tabbar-item>
      <van-tabbar-item @click="addvideo" v-show="showaddbtn"><van-button plain size="mini" icon="plus"></van-button></van-tabbar-item>
      <van-tabbar-item @click="myvideo">我的</van-tabbar-item>
    </van-tabbar>
    
    <!-- 评论弹框 -->
    <van-popup
      position="bottom"
      v-model="pinglunShow"
      get-container=".videolist"
      :style="'height:' + (screeHeight * 0.8) + 'px; overflow-x:hidden; overflow-y: auto;'"
      closeable
      round
    >
      <div class="pinglun_main_title">
        <van-cell title-class="pinglun_title">
          <template #title>
            <div>
              留言评论
            </div>
          </template>
        </van-cell>
      </div>
      <div class="pinglun_list" :style="'max-height:' + (screeHeight * 0.8 + 60 - 130) + 'px;'">
        <van-list
          v-model="pinglunloading"
          :finished="pinglunfinished"
          finished-text="没有更多了"
          @load="pinglunonLoad"
          :immediate-check="false"
        >
          <van-cell-group :border="false" v-for="(item, index) in pinglunlist" :key="index">
            <van-cell>
              <template #icon>
                <van-image
                  round
                  width="40px"
                  height="40px"
                  :src="item.avatar"
                  fit="cover"
                />
              </template>
              <template #title>
                <div class="van-ellipsis">{{item.nickname}}</div>
                <div>{{item.content}}</div>
              </template>
              <template #label>
                <span style="margin-right: 10px;">{{item.createtime_str}}</span>
              </template>
            </van-cell>
          </van-cell-group>
        </van-list>
      </div>
      <div class="poinglun_input van-hairline--top">
        <van-field
          v-model="inputmsg"
          :type="inputType"
          placeholder="留下您的精彩评论吧"
          :autosize="{ maxHeight: 72, minHeight: 24 }"
        >
          <template #right-icon>
            <button @click="sendMsg" :loading="btnloading" v-if="inputmsg" size="small" type="danger" plain>发布</button>
          </template>
        </van-field>
      </div>
    </van-popup>
    <!-- 搜索历史记录 -->
    <div class="searchLine" v-show="showkeylist" :style="'height:' + (screeHeight - 60) + 'px;'">
      <van-popup
        v-model="showkeylist"
        get-container=".searchLine"
        position="top"
        :style="'min-height: 60%; height:' + (screeHeight - 60) + 'px;'"
      >
        <van-cell center v-for="(item, index) in videokeylist" :key="index">
          <template #icon>
            <van-icon name="clock-o" />
          </template>
          <template #title>
            <div @click="keysearch(index, item)">{{item}}</div>
          </template>
          <template #right-icon>
            <van-icon @click="keyDel(index, item)" name="cross" />
          </template>
        </van-cell>
        <van-cell>
          <template #title>
            <div class="nosearch" style="width: 100%;text-align: center;">
              <van-button block @click="RightClick">取消搜索</van-button>
            </div>
          </template>
        </van-cell>
      </van-popup>
    </div>
  </div>
</template>
<script>
import { Dialog } from 'vant';
export default {
  name: "VideoList",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      keywords: "", // 关键词
      loading: false, // 本次加载是否完成
      finished: false, // 是否加载完毕（全部加载完成）
      currSize: 10, // 步数
      currOffset: 0, // 偏移
      shareid: 0,
      merchid: 0,
      active: 1,
      list: [], // 列表
      refreshing: false,
      showkeylist: false, // 显示关键词列表
      rightText: '搜索',
      videokeylist: [], // 搜索记录
      pinglunShow: false,
      inputType: 'textarea', // 默认是text
      inputmsg: '', // 输入内容
      pinglunfinished: false, // 默认评论加载完成
      pinglunloading: false, // 默认评论本次加载完成
      pinglunSize: 20, // 默认评论本次加载完成
      pinglunOffset: 0, // 默认评论本次加载完成
      pinglunlist: [], // 评论列表
      videoinfo: {}, // 目前是哪个视频的留言
      btnloading: false, // 是否执行中
      msgid: 0, // 默认消息id
      videoIndex: 0,
      oldId: "",
      wxConfig: {
        debug: false, // 开启调试模式 true false
        appId: "wxc465682b705715e5",
        jsApiList: [
          "updateAppMessageShareData",
          "updateTimelineShareData",
          "onMenuShareTimeline",
          "onMenuShareAppMessage"
        ], // 必填，需要使用的JS接口列表
        timestamp: 1585098244, // 必填，生成签名的时间戳
        nonceStr: "dfbfwkm1egc", // 必填，生成签名的随机串
        signature: "d8caa8a88d588a8dc5379dbfbde5d875886baed5" // 必填，签名
      },
      videoElement: [], // 视频播放
      userinfo: {}, // 用户信息
      showaddbtn: false, // 默认不显示添加按钮
      videoType: 0, // 默认在云店教程上
      nodata: false, // 默认没有数据
    }
  },
  mounted() {
    this.getinfor(); // 获取用户信息
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "视频列表");
    this.init();
  },
  methods: {
    init(){
      let _that = null;
      _that = this;
      _that.onLoad();
      if(window.isWeixin()){
        setTimeout(() => {
          this.peizhiWxConfig(); // 配置微信分享
        }, 1000);
      }
    },
    // 获取会员信息
    getinfor() {
      let _that = null;
      _that = this;
      const jwtStr = localStorage.getItem("DK_UID")
        ? localStorage.getItem("DK_UID")
        : ""; // 公钥
      _that.$axios.defaults.headers.common['Authorization'] = jwtStr;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(_that.$store.state.domain + "web/user/getinfor")
        .then(function(response) {
          if (response.data) {
            _that.userinfo = response.data;
            if(_that.userinfo.level1_white==1){
              _that.showaddbtn = true;
            }
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    onLoad() {
      console.log("onLoad");
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/videos/get_list",
          _that.$qs.stringify({
            keywords: _that.keywords,
            offset: _that.currOffset,
            size: _that.currSize,
            active: _that.videoType
          })
        )
        .then(res => {
          console.log(res);
          _that.loading = false; // 本次加载是否完成
          _that.refreshing = false;
          _that.nodata = false;
          if (res.data.code == 100000) {
            if (_that.currOffset == 0) {
              _that.list = res.data.data;
              _that.videoElement = document.getElementsByTagName('video');
            } else {
              res.data.data.forEach(item => {
                _that.list.push(item);
              })
              _that.videoElement = document.getElementsByTagName('video');
            }
            if (res.data.data.length >= _that.currSize) {
              _that.currOffset += _that.currSize;
            } else {
              _that.currOffset += res.data.data.length;
              _that.finished = true; // 是否加载完毕（全部加载完成）
            }
          }else{
            _that.finished = true; // 是否加载完毕（全部加载完成）
            if (_that.currOffset == 0) {
              _that.nodata = true;
            }
          }
        })
        .catch(err => {
          _that.finished = true; // 是否加载完毕（全部加载完成）
          if (_that.currOffset == 0) {
            _that.nodata = true;
          }
          console.log(err);
        })
    },
    LeftClick() {
      this.$router.back();
    },
    // 搜索
    RightClick() {
      console.log("搜索");
      this.showkeylist = false;
    },
    goHome() {
      this.$router.replace({ name: "Home", query: {shareid: this.shareid, merchid: this.merchid, _t: 3}});
    },
    addvideo() {
      console.log("发布页面");
      this.$router.push({name: "VideoAdd", query: {shareid: this.shareid, merchid: this.merchid}});
    },
    myvideo() {
      console.log("视频管理页");
      this.$router.push({name: "Myvideo", query: {shareid: this.shareid, merchid: this.merchid}});
    },
    // 下拉刷新
    onRefresh(){
      this.currOffset = 0;
      this.list = [];
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },
    // 取消搜索时触发
    onCancel() {
      this.showkeylist = false;
    },
    inputFocus() {
      this.showkeylist = true;
      this.rightText = "取消";
      let keywords = "";
      keywords = localStorage.getItem("DK_VIDEOSEACH");
      if (keywords) {
        this.videokeylist = keywords.split(",");
      }
    },
    inputBlur() {
      this.showkeylist = true;
      this.rightText = "搜索";
      let keywords = "";
      keywords = localStorage.getItem("DK_VIDEOSEACH");
      if (keywords) {
        this.videokeylist = keywords.split(",");
      }
    },
    // 执行搜索
    onSearch() {
      let _that = null;
      _that = this;
      _that.showkeylist = false;
      if (_that.keywords) {
        let text = "";
        text = localStorage.getItem("DK_VIDEOSEACH") ? localStorage.getItem("DK_VIDEOSEACH") : "";
        if (text) {
          if (text.indexOf(_that.keywords) < 0) {
            text = _that.keywords + "," + text;
            localStorage.setItem("DK_VIDEOSEACH", text);
          }
        }else{
          text = _that.keywords;
          localStorage.setItem("DK_VIDEOSEACH", text);
        }
        _that.currOffset = 0;
        // _that.list = [];
        _that.onLoad();
      } else {
        _that.$refs.keywords.focus();
        _that.$toast("请输入要搜索的内容");
      }
    },
    // 点击搜索记录之行搜索
    keysearch(index, text) {
      console.log(index, text);
      this.videokeylist.splice(index, 1);
      this.videokeylist.unshift(text);
      let words = "";
      this.videokeylist.forEach(item => {
        words += item + ",";
      })
      words = words.substr(0, words.length - 1);
      localStorage.setItem("DK_VIDEOSEACH", words);
      this.keywords = text;
      this.onSearch();
    },
    // 删除搜索记录
    keyDel(index, text) {
      console.log(index, text);
      this.videokeylist.splice(index, 1);
      let keywords = "";
      this.videokeylist.forEach(item => {
        keywords + item + ",";
      })
      keywords = keywords.substr(0, keywords.length - 1);
      localStorage.setItem("DK_VIDEOSEACH", keywords);
    },
    // 评论
    liuyan (index) {
      console.log(index);
      this.pinglunShow = true;
      this.videoinfo = this.list[index];// 视频info
      this.pinglunOffset = 0;
      this.pinglunlist = [];
      this.pinglunonLoad();
    },
    // 分享
    fenxiang(index) {
      let _that = null;
      _that = this;
      let title = "";
      let desc = "";
      let img = "";
      title = _that.list[index].title;
      desc = _that.list[index].title;
      img = _that.list[index].thumb;
      window.wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
        window.wx.updateAppMessageShareData({ 
          title: title, // 分享标题
          desc: desc, // 分享描述
          link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: img, // 分享图标
          type: "link",
          success: function() {
            console.log("分享设置成功");
            _that.setShareNum(index)
          }
        })
      });
    },
    // 增加分享数量
    setShareNum(index){
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/videos/set_shares",
          _that.$qs.stringify({
            id: _that.list[index].id
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.list[index].shares = res.data.num;
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 删除
    shanchu(index) {
      console.log(index);
      let _that = null;
      _that = this;
      this.$dialog.alert({
        message: "确认要删除该视频？",
        showCancelButton: true,
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        beforeClose: function (action, done) {
          if (action == "confirm") {
            _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
            _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
            _that.$axios
              .post(
                _that.$store.state.domain + "web/videos/del_video",
                _that.$qs.stringify({
                  id: _that.list[index].id
                })
              )
              .then(res => {
                console.log(res);
                if (res.data.code == 100000) {
                  _that.$toast(res.data.msg ? res.data.msg : "删除成功");
                  _that.list.splice(index, 1);
                }else {
                  _that.$toast(res.data.msg ? res.data.msg : "删除失败");
                }
              })
              .catch(err => {
                console.log(err);
              })
          }
          done();
        }
      });
    },
    // 获取评论内容
    pinglunonLoad() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/videos/get_msg",
          _that.$qs.stringify({
            videoid: _that.videoinfo.id,
            size: _that.pinglunSize,
            offset: _that.pinglunOffset
          })
        )
        .then(res => {
          console.log(res);
          _that.pinglunloading = false;
          if (res.data.code == 100000) {
            if (_that.pinglunOffset == 0) {
              _that.pinglunlist = res.data.data;
            }else{
              res.data.data.forEach(item => {
                _that.pinglunlist.push(item);
              })
            }
            
            if(res.data.data.length >= _that.pinglunSize) {
              _that.pinglunOffset += _that.pinglunSize;
              _that.pinglunfinished = false;
            }else {
              _that.pinglunOffset += res.data.data.length;
              _that.pinglunfinished = true;
            }
          }else{
            _that.pinglunfinished = true;
          }
        })
        .catch(err => {
          _that.pinglunloading = false;
          _that.pinglunfinished = true;
          console.log(err);
        })
    },
    // 发表评论
    sendMsg() {
      let _that = null;
      _that = this;
      
      if(!_that.btnloading) {
        _that.btnloading = true;
        _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/videos/add_msg",
            _that.$qs.stringify({
              videoid: _that.videoinfo.id,
              msgid: _that.msgid,
              msg: _that.inputmsg
            })
          )
          .then(res => {
            _that.btnloading = false;
            console.log(res);
            if (res.data.code == 100000) {
              _that.$toast("提交成功");
              _that.inputmsg = "";
              _that.pinglunOffset = 0;
              this.pinglunonLoad();
            }
          })
          .catch(err => {
            _that.btnloading = false;
            console.log(err);
          })
      }
    },

    // 分享初始化
    peizhiWxConfig() {
      let _that = null;
      _that = this;

      /* 获取wx初始化配置 */
      _that.$axios
        .post(
          _that.$store.state.domain + "web/wetch/wx_sign",
          _that.$qs.stringify({
            url: encodeURIComponent(window.location.href)
          })
        )
        .then(function(response) {
          _that.wxConfig.timestamp = response.data.timestamp;
          _that.wxConfig.nonceStr = response.data.nonceStr;
          _that.wxConfig.signature = response.data.signature;
          window.wx.config(_that.wxConfig); // wx初始化配置
        })
        .catch(function(error) {
          console.log(error, "error");
          window.getWx = _that.getWxConfig; // 微信签名过期后调用 外部JS
        });
    },
    /* 获取配置失效 */
    getWxConfig(err) {
      // 如果过期，从html JS 调用重新签名
      if (err.indexOf("invalid signature") != -1) {
        let $this = null;
        $this = this;
        this.$axios
          .post(
            $this.$store.state.domain + "web/wetch/wx_sign",
            $this.$qs.stringify({
              url: encodeURIComponent(window.location.href)
            })
          )
          .then(function(response) {
            console.log(response);
            this.peizhiWxConfig(); // 配置微信分享
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    },

    videoplayer(index){
      let _that = null;
      _that = this;

      _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/videos/set_views",
          _that.$qs.stringify({
            id: _that.list[index].id
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.list[index].views = res.data.num;
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    tabsClick(name, title) {
      this.videoType = name;
      this.currOffset = 0;
      this.list = [];
      this.onLoad();
    },

    // 查看视频
    detail(index) {
      let that = null;
      that = this;
      let id = 0; 
      id = this.list[index].id;
      let isSalse = 1; 
      isSalse = this.list[index].isSalse;
      let goodsid = 0;
      goodsid = this.list[index].goodsid;
      if (isSalse == 1) {
        Dialog.confirm({
          title: '暂无权限',
          message: '您暂未观看权限，购买后可观看。是否去购买？',
          confirmButtonText: '去购买'
        })
        .then(() => {
          // on confirm
          that.dogoods(goodsid);
        })
        .catch(() => {
          // on cancel
        });
        return false;
      }
      that.$router.push({
        path: '/videoDetail',
        query: {
          id: id,
          shareid: that.shareid,
          merchid: that.merchid,
        }
      });
    },
    dogoods(id) {
      let that = null;
      that = this;
      that.$router.push({
        path: '/videoDetail',
        query: {
          id: id,
          shareid: that.shareid,
          merchid: that.merchid,
        }
      });
    },
  }
}
</script>
<style lang="less">
  .videolist {
    width: 100%;
    min-height: 100%;
    background: #fff;
    overflow: hidden;
    position: relative;
    z-index: 2;
    .van-nav-bar {
      z-index: 4;
      background: #fff;
      .van-nav-bar__left,
      .van-nav-bar__right {
        width: 50px;
        padding: 0;
        text-align: center;
        color: #333;
        justify-content:center;
      }
      .van-nav-bar__title{
        flex: 1;
        max-width: 70%;
      }
      .van-icon {
        color: #333;
      }
      .van-search {
        background: rgba(0,0,0,0);
        padding: 10px 0;
        .van-cell__value {
          flex: 1;
          .van-field__control {
            color: #333;
          }
        }
      }
    }

    // 视频列表
    .main {
      position: relative;
      margin: 60px 0;
      overflow: hidden;
      width: 100%;
      .van-tabs__nav {
        color: #333;
        .van-tab__text {
          color: #333;
        }
      }
      .main_list {
        padding-left: 10px;
        .van-grid-item__content {
          padding: 0;
          margin: 10px 10px 0 0;
        }
        .list_miaoshu {
          font-size: 12px;
          color: #333;
          overflow: hidden;
          border-radius: 10px;
          position: relative;
        }
        .list_item_icon {
          position: absolute;
          top: 10px;
          left: 50%;
          margin-left: -15px;
        }
        .list_text {
          position: absolute;
          background-color: rgba(0,0,0,0.5);
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
        .list_text_item1,
        .list_text_item2 {
          height: 20px;
          font-size: 12px;
          color: #fff;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
          position: absolute;
          left: 10px;
          right: 10px;
          text-align: left;
        }
        .list_text_item1 {
          bottom: 30px;
        }
        .list_text_item2 {
          bottom: 10px;
        }
      }
    }
    // 底部菜单
    .van-tabbar {
      color: #333;
      .van-tabbar-item {
        font-size: 16px;
        .van-button {
          color: #333;
          padding: 5px 10px;
          border-width: 2px;
          border-radius: 6px;
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
    // 搜索列表
    .searchLine {
      width: 100%;
      position: fixed;
      top: 50px;
      left: 0;
      right: 0;
      z-index: 3;
      .van-popup {
        position: absolute;
        background-color: #fff;
        .van-cell {
          background-color: #fff;
          &::after {
            border-color: #333;
          }
          color: #333;
          .van-cell__title {
            flex: 1;
            text-align: left;
            color: #333;
            padding: 0 10px;
          }
          .nosearch {
            .van-button {
              background-color: #fff;
              color: #333;
              border-color: #161922;
            }
          }
        }
      }
      .van-overlay {
        position: absolute;
      }
    }
    // 评论样式
    .van-popup {
      padding-bottom: 60px;
      .pinglun_main_title {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        overflow: hidden;
        .pinglun_title {
          flex: 1;
          margin-top: 5px;
        }
      }
      .pinglun_list {
        overflow-x: hidden;
        overflow-y: auto;
        height: 100%;
        margin: 60px 0 0;
        .van-cell {
          padding: 10px 10px 0;
          &:last-of-type {
            padding-bottom: 10px;
          }
          .van-image {
            margin-right: 10px;
          }
          .van-cell__title {
            flex: 1;
            text-align: left;
            overflow: hidden;
          }
        }
      }
      .poinglun_input {
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        .van-field {
          .van-field__value {
            flex: 1;
          }
          .van-field__right-icon {
            button {
              color: #ee0a24;
              border-color: #ee0a24;
              background-color: #333;
              border-width: 1px;
              font-size: 14px;
              line-height: 20px;

            }
          }
        }
      }
    }
  }
</style>